import React from "react";
import {HeadFC} from "gatsby";
import {Header} from "../components/header";
import {Footer} from "../components/footer";
import {useParosSkeletonStyle} from "../hooks/use_paros_skeleton";
import {SEO} from "../components/seo";

const InsurancePackagesPage = () => {
  return (
    <>
      <Header/>
      <main className="h-full">
        <section className="h-full sm:h-1/5 max-w-6xl mx-auto pt-10 pb-14" style={useParosSkeletonStyle()}>
          <div className="py-8 lg:py-16 px-4 mx-auto max-w-screen-md">
            <h1 className="mb-4 text-4xl tracking-tight font-extrabold text-gray-900 font-poppins">
              Information About the Driver
            </h1>
          </div>
        </section>
        <section className="w-full bg-white py-20 px-8">
          <div className="max-w-6xl mx-auto">
            <h2 className="text-2xl mb-4"><b>Information About the Driver</b></h2>
            <h3><b>Rental Ages</b></h3>
            <p className="mb-4">At Coolcar, we have age requirements for renters. The minimum rental age is 21 years, and the maximum rental age is 75 years.</p>
            <h3><b>Driving License Requirements</b></h3>
            <p className="mb-4">For the rental process, we require a valid European or international driving license that was issued at least one year ago.</p>
            <h3><b>Fuel Policy</b></h3>
            <p className="mb-4">We deliver the rented vehicle to you with a specific amount of gasoline, and you must return it with the same level of gasoline. If you don't, you'll be charged the difference.</p>
            <h3><b>Fines and Penalties</b></h3>
            <p className="mb-4">You are liable for any road fines or parking tickets incurred while driving the rented vehicle on the island of Paros during the rental period.</p>
            <h3><b>Loss or Destruction of a Key</b></h3>
            <p className="mb-4">If you lose or damage the key to the car during the rental period, you are responsible for its value and delivery cost.</p>
            <h3><b>Rental Extension</b></h3>
            <p className="mb-4">If you wish to extend your rental period, please contact Coolcar rentals to check availability.</p>
            <h3><b>Transporting Vehicles on Ferries</b></h3>
            <p className="mb-4">Transporting a rented vehicle on a ferry requires prior written approval from Coolcar rentals. Any damages that occur during transportation will not be covered.</p>
            <h3><b>Vehicle Category Changes</b></h3>
            <p className="mb-4">Coolcar rentals reserves the right, depending on availability, to provide a different type of car with the same or greater category than the original rental vehicle.</p>
          </div>
        </section>
      </main>
      <Footer/>
    </>
  )
}

export const Head: HeadFC = () => (
  <SEO
    title="Information About the Driver | Coolcar Rentals Paros"
    description="Learn about driver requirements, fuel policies, fines and penalties, and more when renting a car from Coolcar Rentals on the island of Paros. Book now!"
  />
);

export default InsurancePackagesPage;